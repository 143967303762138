import React from "react";
import loaderGif from "../assets/images/logo.webp";
import "../assets/styles/Loader.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={loaderGif} alt="Loading" className="loader-image" />
    </div>
  );
};

export default Loader;
