import React,{Suspense} from "react";
import Loader from "../components/Loader";
import Hero from "../components/Hero";
const Interested = React.lazy(() => import("../components/Interested"));
const FeaturesCards = React.lazy(() => import("../components/Cards/FeaturesCard"));


function Home({companyDetails}) {
    return ( 
        <Suspense fallback={<Loader />}>
        <Hero companyDetails={companyDetails} />
        <FeaturesCards companyDetails={companyDetails} />
        <Interested/>
        </Suspense>

     );
}

export default Home;