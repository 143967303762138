import {
  createStyles,
  Container,
  Title,
  Text,
  Button,
  rem,
} from "@mantine/core";
import { scroller } from "react-scroll";
import desktopBackground from "../../src/assets/images/background.webp";
import mobileBackground from "../../src/assets/images/bg_mobile.webp";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    position: "relative",
    overflow: "hidden",
    backgroundImage: `url(${desktopBackground})`,
    backgroundSize:"cover",
    width: "100%",
    height: "100%",
    [theme.fn.smallerThan("md")]: {
      backgroundImage: `url(${mobileBackground})`,
    },
  },


  inner: {
    display: "flex",
    justifyContent: "space-between",

    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },

  content: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    marginRight: `calc(${theme.spacing.xl} * 3)`,
    [theme.fn.smallerThan("md")]: {
      marginRight: 0,
    },
    backdropFilter: "blur(1.2px)",
  },

  title: {
    color: theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.05,
    maxWidth: rem(500),
    fontSize: rem(48),

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      fontSize: rem(34),
      lineHeight: 1.15,
    },
  },

  description: {
    color: theme.black,
    opacity: 1,
    maxWidth: rem(500),

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },

  control: {
    paddingLeft: rem(50),
    paddingRight: rem(50),
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(20),

    [theme.fn.smallerThan("md")]: {
      width: "100%",
    },
  },
}));

export default function Hero(companyDetails) {
  const { classes } = useStyles();
  const heroTitle = companyDetails?.companyDetails[0].heroTitle;
  const heroDesc = companyDetails?.companyDetails[0].heroDescription;

  const scrollToForm = () => {
    scroller.scrollTo("bookNowForm", {
      smooth: true,
      offset: -50,
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Container size="lg">
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>{heroTitle}</Title>

              <Text className={classes.description} mt={30}>
                {heroDesc}
              </Text>

              <Button
                variant="gradient"
                gradient={{ from: "#5ECFEE", to: "#736efe" }}
                size="lg"
                className={classes.control}
                mt={40}
                onClick={scrollToForm}
              >
                Book Now!
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
