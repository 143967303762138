import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import Loader from "./components/Loader";
import { MantineProvider } from "@mantine/core";
import "./App.css";
import Home from "./pages/Home";

const Navbar = React.lazy(() => import("./components/Navbar"));
const Footer = React.lazy(() => import("./components/Footer"));
const Cartstate = React.lazy(() => import("./Contexts/Cartstate"));
// const Home = React.lazy(() => import("./pages/Home"));
const GetInTouch = React.lazy(() => import("./pages/Contact"));
const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const PeriodicChecks = React.lazy(() => import("./pages/PeriodicChecks"));
const ShopPage = React.lazy(() => import("./pages/Shop"));
const Videos = React.lazy(() => import("./pages/Videos"));
const Blogs = React.lazy(() => import("./pages/Blog"));
const About = React.lazy(() => import("./pages/AboutUs"));
const Testimonials = React.lazy(() => import("./pages/Testimonials"));
const Gallery = React.lazy(() => import("./pages/Gallery"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const BlogView = React.lazy(() => import("./components/Blog/BlogView/BlogView"));
const BlogForm = React.lazy(() => import("./components/Forms/BlogForm"));
const Checkout = React.lazy(() => import("./components/Checkout/Checkout"));
const ProductPage = React.lazy(() => import("./components/Shop/ProductPage/ProductPage"));
const Forgot = React.lazy(() => import("./pages/ForgotPassword"));
const GalleryDetailPage = React.lazy(() => import("./pages/GalleryDetailPage"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/companyDetails/`);
        setCompanyDetails(response?.data.response.data);
      } catch (error) {
        console.error("Error fetching company details:", error);
        setError(true);
      }
      setIsLoading(false);
    };

    fetchCompanyDetails();
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <Loader />
      ) : error ? (
        <>
          <h3 style={{ color: "red" }}>Server Error! Please try again later.</h3>
          <Loader />
        </>
      ) : (
        <MantineProvider>
          <Cartstate>
          <Suspense fallback={<Loader />}>
            <Router>
              <Navbar />
              <Routes>
                <Route path="/" element={<Home companyDetails={companyDetails} />} />
                <Route path="/contact" element={<GetInTouch companyDetails={companyDetails} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<Forgot />} />
                <Route path="/periodicChecks" element={<PeriodicChecks />} />
                <Route path="/shop" element={<ShopPage />} />
                <Route path="/videos" element={<Videos />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/about" element={<About companyDetails={companyDetails} />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/gallery/:id" element={<GalleryDetailPage />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/blogs/:id" element={<BlogView />} />
                <Route path="/blogs/new" element={<BlogForm />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/shop/product/:id" element={<ProductPage />} />
                <Route path="/change-password/:id/:token" element={<ChangePassword />} />
              </Routes>
              <Footer companyDetails={companyDetails} />
            </Router>
          </Suspense>
          </Cartstate>
        </MantineProvider>
      )}
    </div>
  );
}

export default App;
